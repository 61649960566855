window.outlineCardInit = (widgetId, carouselLength, cardPerView) => {
    let swiperOptions = {
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
        mousewheel: {
            forceToAxis: true,
            eventsTarget: `.outline-card-carousel-${widgetId} .swiper-wrapper`,
        },
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
            nextEl: `.swiper-button-next-${widgetId}`,
            prevEl: `.swiper-button-prev-${widgetId}`,
        },
        pagination: {
            el: `.swiper-pagination-outline-card-${widgetId}`,
            clickable: true,
        },
        preventClicks: true,
        preventClicksPropagation: true,
        breakpoints: {
            992: {
                slidesPerView: cardPerView,
            },
        },
    };
    var swiperoutlineCard = new window.Swiper(
        `.outline-card-carousel-${widgetId}`,
        swiperOptions
    );
};
