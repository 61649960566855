window.galleryInit = (widgetId, cardsLength) =>{
    let swiperOptions = {
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        mousewheel: {
          forceToAxis: true,
          eventsTarget: `.gallery-carousel-${widgetId} .swiper-wrapper`,
      },
        pagination: {
          el: `.swiper-pagination-${widgetId}`,
          clickable:true
        }, 
        loop: false,
        initialSlide:cardsLength >= 4 ? -1 : 1, 
        slidesPerView:"auto",
        preventClicks: true,
        preventClicksPropagation: true,
        centeredSlides: true,
        spaceBetween: 16,
        navigation: {
            nextEl: `.swiper-gallery-button-next-${widgetId}`,
            prevEl: `.swiper-gallery-button-prev-${widgetId}`,
          },
          breakpoints: {
            768: {
              loop: cardsLength >= 4 ? true : false,
            },
        }

      }
    var swiperGallery = new window.Swiper(`.gallery-carousel-${widgetId}`, swiperOptions);    

    }
