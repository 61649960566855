window.generateMap = (latitude, longitude, mapId) => {
    const myLatLng = { lat: latitude, lng: longitude };
    const map = new google.maps.Map(document.getElementById(mapId), {
      zoom: 15,
      center: myLatLng,
      disableDefaultUI: true,
    });

    const customIcon = {
      url:
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="39" viewBox="0 0 33 39" fill="none">
          <path d="M5.89314 24.18L16.1074 36.27L29.0717 23.01L30.6431 13.65L25.9289 4.67995L16.1074 1.94995L5.89314 4.67995L2.35742 13.65L5.89314 24.18Z" fill="#00843D"/>
          <path d="M16.5 19.5C14.2312 19.5 12.375 17.745 12.375 15.6C12.375 13.455 14.2312 11.7 16.5 11.7C18.7687 11.7 20.625 13.455 20.625 15.6C20.625 17.745 18.7687 19.5 16.5 19.5ZM28.875 15.99C28.875 8.9115 23.4094 3.9 16.5 3.9C9.59062 3.9 4.125 8.9115 4.125 15.99C4.125 20.553 8.14687 26.598 16.5 33.813C24.8531 26.598 28.875 20.553 28.875 15.99ZM16.5 0C25.1625 0 33 6.279 33 15.99C33 22.464 27.4931 30.1275 16.5 39C5.50688 30.1275 0 22.464 0 15.99C0 6.279 7.8375 0 16.5 0Z" fill="white"/>
        </svg>
      `),
      scaledSize: new google.maps.Size(40, 40),
    };

    new google.maps.Marker({
      position: myLatLng,
      map: map,
      icon: customIcon
    });
  }


  window.generateMapWithPins = (pins, mapId) => {
    const bounds = new google.maps.LatLngBounds();
    const map = new google.maps.Map(document.getElementById(mapId), {
      zoom: 15,
      center: { lat: 0, lng: 0 },
      disableDefaultUI: true,
    });
  
    const customIcon = {
      url:
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none">
          <path d="M3.92843 16.1201L10.738 24.18L19.3808 15.34L20.4284 9.10005L17.2856 3.12005L10.738 1.30005L3.92843 3.12005L1.57129 9.10005L3.92843 16.1201Z" fill="white"/>
          <path d="M11 13C9.4875 13 8.25 11.83 8.25 10.4C8.25 8.97 9.4875 7.8 11 7.8C12.5125 7.8 13.75 8.97 13.75 10.4C13.75 11.83 12.5125 13 11 13ZM19.25 10.66C19.25 5.941 15.6062 2.6 11 2.6C6.39375 2.6 2.75 5.941 2.75 10.66C2.75 13.702 5.43125 17.732 11 22.542C16.5688 17.732 19.25 13.702 19.25 10.66ZM11 0C16.775 0 22 4.186 22 10.66C22 14.976 18.3288 20.085 11 26C3.67125 20.085 0 14.976 0 10.66C0 4.186 5.225 0 11 0Z" fill="#00843D"/>
        </svg>
      `),
      scaledSize: new google.maps.Size(22, 26),
    };
  
    const activeCustomIcon = {
      url:
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="49" viewBox="0 0 42 49" fill="none">
          <path d="M7.5 30.38L20.5 45.57L37 28.91L39 17.15L33 5.88L20.5 2.45L7.5 5.88L3 17.15L7.5 30.38Z" fill="#00843D"/>
          <path d="M21 24.5C18.1125 24.5 15.75 22.295 15.75 19.6C15.75 16.905 18.1125 14.7 21 14.7C23.8875 14.7 26.25 16.905 26.25 19.6C26.25 22.295 23.8875 24.5 21 24.5ZM36.75 20.09C36.75 11.1965 29.7937 4.9 21 4.9C12.2063 4.9 5.25 11.1965 5.25 20.09C5.25 25.823 10.3687 33.418 21 42.483C31.6313 33.418 36.75 25.823 36.75 20.09ZM21 0C32.025 0 42 7.889 42 20.09C42 28.224 34.9912 37.8525 21 49C7.00875 37.8525 0 28.224 0 20.09C0 7.889 9.975 0 21 0Z" fill="white"/>
        </svg>
      `),
      scaledSize: new google.maps.Size(26, 30),
    };
  
    const markers = [];
    const tooltips = [];
    const activeMarkers = [];
  
    function resetActiveState() {
      activeMarkers.forEach(({ marker, tooltip }) => {
        marker.setIcon(customIcon);
        tooltip.style.display = "block";
      });
      activeMarkers.length = 0;
    }
  
    function handleFocus(index) {
      const card = document.querySelectorAll(".school-card-map")[index];
      const popup = document.querySelector(`.school-info-popup-${index + 1}`);
      const position = markers[index].getPosition();
  
      resetActiveState();
  
      document.querySelectorAll(".school-card-map").forEach((otherCard, otherIndex) => {
        if (otherIndex !== index) {
          otherCard.classList.remove("focused");
          const otherPopup = document.querySelector(`.school-info-popup-${otherIndex + 1}`);
          otherPopup.classList.remove("d-block");
          otherPopup.classList.add("d-none");
        }
      });
  
      popup.classList.remove("d-none");
      popup.classList.add("d-block");
      card.classList.add("focused");
      map.panTo(position);
      map.setZoom(10);
  
      const activeMarker = markers[index];
      const activeTooltip = tooltips[index];
      activeMarker.setIcon(activeCustomIcon);
      activeTooltip.style.display = "none";
      activeMarkers.push({ marker: activeMarker, tooltip: activeTooltip });
    }
  
    function handleBlur(index) {
      const card = document.querySelectorAll(".school-card-map")[index];
      const popup = document.querySelector(`.school-info-popup-${index + 1}`);
      popup.classList.remove("d-block");
      popup.classList.add("d-none");
      card.classList.remove("focused");
      resetActiveState();
      map.fitBounds(bounds);
      map.setZoom(8);
    }
  
    pins.forEach((pin, index) => {
      const position = new google.maps.LatLng(pin.lat, pin.lng);
  
      const marker = new google.maps.Marker({
        position: position,
        map: map,
        name: pin.name,
        icon: customIcon,
      });
  
      markers.push(marker);
      bounds.extend(position);
  
      const tooltip = document.createElement("div");
      tooltip.className = "custom-tooltip";
      tooltip.style.transform = "translate(-50%, -100%)";
      tooltip.innerHTML = pin.name;
  
      const overlay = new google.maps.OverlayView();
      overlay.onAdd = function () {
        const panes = this.getPanes();
        panes.floatPane.appendChild(tooltip);
      };
  
      overlay.draw = function () {
        const projection = this.getProjection();
        const point = projection.fromLatLngToDivPixel(marker.getPosition());
        if (point) {
          tooltip.style.left = `${point.x}px`;
          tooltip.style.top = `${point.y - 30}px`;
        }
      };
  
      overlay.onRemove = function () {
        tooltip.parentNode.removeChild(tooltip);
      };
  
      overlay.setMap(map);
      tooltips.push(tooltip);
  
      marker.addListener("click", () => {
        const isMarkerFocused = document.querySelectorAll(".school-card-map")[index].classList.contains("focused");
        if (isMarkerFocused) {
          handleBlur(index);
        } else {
          handleFocus(index);
        }
      });
  
      marker.addListener("mouseover", () => {
        tooltip.style.display = "block";
      });
  
      marker.addListener("mouseout", () => {
        const zoom = map.getZoom();
        if (zoom <= 8) {
          tooltip.style.display = "none";
        }
      });
    });
  
    if (pins.length === 1) {
      // Aggiunto controllo per un solo pin
      const singlePin = pins[0];
      map.setCenter(new google.maps.LatLng(singlePin.lat, singlePin.lng));
      map.setZoom(7);
    } else {
      map.fitBounds(bounds);
    }
  
    map.addListener("zoom_changed", () => {
      const zoom = map.getZoom();
      tooltips.forEach((tooltip) => {
        tooltip.style.display = zoom > 8 ? "block" : "none";
      });
    });
  
    document.querySelectorAll(".school-card-map").forEach((card, index) => {
      card.addEventListener("click", function () {
        const isCardAlreadyFocused = card.classList.contains("focused");
        if (isCardAlreadyFocused) {
          handleBlur(index);
        } else {
          handleFocus(index);
        }
      });
  
      document.querySelector(`.button-close-popup-${index + 1}`).addEventListener("click", function () {
        handleBlur(index);
      });
    });
  
    document.querySelector(".close-modal-icon-map").addEventListener("click", function () {
      document.querySelectorAll(".school-card-map").forEach((card) => {
        card.classList.remove("focused");
      });
  
      document.querySelectorAll(".school-info-popup").forEach((popup) => {
        popup.classList.remove("d-block");
        popup.classList.add("d-none");
      });
  
      resetActiveState();
      map.fitBounds(bounds);
      map.setZoom(7);
    });
  };
  