// todo: oldDates
// window.countdownInit = (expireDate, widgetId) => {
// todo: newDates
window.countdownInit = (expireDate, widgetId, timezone) => {

    var parts = expireDate.split(/[- :]/);
    let convertedDate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
    let timer;

   timer = setInterval(function() {
        timeBetweenDates(convertedDate);
      }, 1000);
      
      function timeBetweenDates(toDate) {

        // todo: oldDate
        // var dateEntered = toDate;
        // var now = new Date();
        //   var nowUtc = new Date(
        //       now.getUTCFullYear(),
        //       now.getUTCMonth(),
        //       now.getUTCDate(),
        //       now.getUTCHours(),
        //       now.getUTCMinutes(),
        //       now.getUTCSeconds()
        //   );
          // var difference = dateEntered.getTime() - nowUtc.getTime();

          // todo: newDate
          var difference = toDate.getTime() - getCurrentDateWithFullTimezone(timezone);

        if (difference <= 0) {
      
          // Timer done
          clearInterval(timer);
          document.querySelector(`#countdown-day-${widgetId}`) && (document.querySelector(`#countdown-day-${widgetId}`).innerHTML = 0);
          document.querySelector(`#countdown-hour-${widgetId}`) && (document.querySelector(`#countdown-hour-${widgetId}`).innerHTML = 0);
          document.querySelector(`#countdown-mins-${widgetId}`) && (document.querySelector(`#countdown-mins-${widgetId}`).innerHTML = 0);
          document.querySelector(`#countdown-sec-${widgetId}`) && (document.querySelector(`#countdown-sec-${widgetId}`).innerHTML = 0);
        
        } else {
          var seconds = Math.floor(difference / 1000);
          var minutes = Math.floor(seconds / 60);
          var hours = Math.floor(minutes / 60);
          var days = Math.floor(hours / 24);
      
      
          hours %= 24;
          minutes %= 60;
          seconds %= 60;
          document.querySelector(`#countdown-day-${widgetId}`) && (document.querySelector(`#countdown-day-${widgetId}`).innerHTML = days);
          document.querySelector(`#countdown-hour-${widgetId}`) && (document.querySelector(`#countdown-hour-${widgetId}`).innerHTML = hours);
          document.querySelector(`#countdown-mins-${widgetId}`) && (document.querySelector(`#countdown-mins-${widgetId}`).innerHTML = minutes);
          document.querySelector(`#countdown-sec-${widgetId}`) && (document.querySelector(`#countdown-sec-${widgetId}`).innerHTML = seconds);
        }
    }
}

// todo: newDate
/**
 *
 * @param timezone
 * @returns {string}
 */
function getCurrentDateWithFullTimezone(timezone) {
    // Get the current date and time
    const now = new Date();

    // Format the date to match the desired output
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    // Format the date and time string
    const formattedDate = formatter.format(now);

    // Convert the formatted date string back to a Date object
    // The Date constructor parses dates in format "MM/DD/YYYY, HH:MM:SS AM/PM"
    // You may need to adjust based on your locale
    const parsedDate = new Date(formattedDate + ' GMT' + (now.getTimezoneOffset() > 0 ? '-' : '+') +
        ('00' + Math.abs(now.getTimezoneOffset() / 60).toString()).slice(-2) + '00');

    // Return the Unix timestamp in milliseconds
    return parsedDate.getTime();
}