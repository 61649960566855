window.peopleModalHandler = (idWidget,index, peopleCards) => {
    let modalQuote = document.querySelector(`.modal-quote-${idWidget}`);
    if (modalQuote.classList.contains("d-none")) {
        modalQuote.classList.replace("d-none", "d-flex");
        document.body.style.overflow = "hidden";
        document.querySelector(`.modal-image-${idWidget}`).innerHTML = peopleCards[index-1].image
        document.querySelector(`.modal-quote-name-${idWidget}`).innerHTML = peopleCards[index-1].name + ' ' + peopleCards[index-1].surname 
        document.querySelector(`.modal-quote-job-${idWidget}`).innerHTML = peopleCards[index-1].jobTitle
        document.querySelector(`.modal-quote-quote-${idWidget}`).innerHTML = peopleCards[index-1].description
    } else {
        modalQuote.classList.replace("d-flex", "d-none");
        document.body.style.overflow = "auto"; 
    }
}

window.oncloseModal = (widgetId) => {
    let modalQuote = document.querySelector(`.modal-quote-${widgetId}`);
    if (modalQuote.classList.contains("d-flex")) {
        modalQuote.classList.replace("d-flex", "d-none");
        document.body.style.overflow = "auto"; 
    }
}