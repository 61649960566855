import $ from "jquery";

window.pushEventToDataLayer = (eventName) => {
    $(document).ready(function checkFormHS() {
        if ($('.hs-form').length > 0) {
            var form = $('.hs-form');
            form.on('hsvalidatedsubmit', function (e) {
                let currentUrl = window.location.href;
                if (currentUrl.includes('events')) {
                    sessionStorage.setItem('eventValue', eventName);
                }
            })
        } else {
            setTimeout(function () { checkFormHS(); }, 200);
        };
    });
}

