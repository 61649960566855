window.communitySliderInit = (widgetId) => {

  var galleryThumbs = new window.Swiper(`.community-slider-thumbs-${widgetId}`, {
    mousewheel: {
      forceToAxis: true,
      eventsTarget: `.community-slider-content-${widgetId} .swiper-wrapper`,
    },
    slidesPerView: 4,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    spaceBetween: 16,
    watchSlidesProgress: true,
    navigation: {
      nextEl: `.swiper-community-button-next-${widgetId}`,
      prevEl: `.swiper-community-button-prev-${widgetId}`,
    },
    breakpoints: {
      992: {
        slidesPerView: 6,
      },
    }

  }
  )

  var swiper = new window.Swiper(`.community-slider-content-${widgetId}`, {
    mousewheel: {
      forceToAxis: true,
      eventsTarget: `.community-slider-content-${widgetId} .swiper-wrapper`,
    },
    slidesPerView: 1,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    spaceBetween: 16,
    speed:900,
    thumbs: {
      swiper: galleryThumbs,
    },

  }
  )

  //Mobile
  var galleryThumbsMobile = new window.Swiper(`.community-slider-thumbs-mobile-${widgetId}`, {
    mousewheel: {
      forceToAxis: true,
      eventsTarget: `.community-slider-thumbs-mobile-${widgetId} .swiper-wrapper`,
    },
    slidesPerView: 2,
    centeredSlides: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    spaceBetween: 16,
    loop: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        loop: false,
      },
    }


  }
  )

  var swiperMobile = new window.Swiper(`.community-slider-content-mobile-${widgetId}`, {
    mousewheel: {
      forceToAxis: true,
      eventsTarget: `.community-slider-content-mobile-${widgetId} .swiper-wrapper`,
    },
    slidesPerView: 1,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    spaceBetween: 16,
    speed:900,
    loop: false,
    breakpoints: {
      768: {
        loop: false,
      },
    }

  }
  )
  swiperMobile.controller.control = galleryThumbsMobile;
  galleryThumbsMobile.controller.control = swiperMobile;

}

window.onCloseModalVideo = (idWidget, widgetName) => {
  let modalVideo = document.querySelector(`.modal-video-${idWidget}`)

  if (modalVideo.classList.contains("d-none")) {
      modalVideo.classList.replace("d-none", "d-flex");
      document.body.style.overflow = "hidden";
  } else {
      modalVideo.classList.replace("d-flex", "d-none");
      document.body.style.overflow = "auto";
      let video = document.querySelector(`#${widgetName}_${idWidget} video#widgetCustomVideo_${idWidget}`);
      video.pause();
      video.muted = false;
  }

  let video = document.querySelector(`#${widgetName}_${idWidget} video#widgetCustomVideo_${idWidget}`);
  video.addEventListener('pause', function () {
      video.pause();
  });
}

window.openCommunityVideo = (idWidget, widgetName, rtl, srcVideo) => {
  let modalVideo = document.querySelector(`.modal-video-${idWidget}`);
  if (modalVideo.classList.contains("d-none")) {
      modalVideo.classList.replace("d-none", "d-flex");
      document.body.style.overflow = "hidden";
  } else {
      modalVideo.classList.replace("d-flex", "d-none");
      document.body.style.overflow = "auto";
  }

  let videoContainer = document.querySelector(`#widgetCustomVideo_${idWidget}`)
  videoContainer.setAttribute('src', srcVideo);

}