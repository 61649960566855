window.viewContinent = (widgetId, widgetName, key) => {
    const buttons = document.querySelectorAll(`.button-tabs`);
    const image = document.getElementById(`image-school-map-${widgetId}-${widgetName}`);
    const selectedButton = document.getElementById(`button-tab-${key}-${widgetId}-${widgetName}`);
    const containers = document.querySelectorAll(`div[id^="container-"]`);
    const selectedContainer = document.getElementById(`container-${key}`);
    
    if (selectedButton.classList.contains('is-active')) {
        selectedButton.classList.remove('is-active');
        image.src = `/images/all-gray.svg`; 
        if (selectedContainer) {
            selectedContainer.classList.replace('d-block', 'd-none');
        }
    } else {
        buttons.forEach((button) => {
            button.classList.remove('is-active');
        });
        selectedButton.classList.add('is-active');
        if(window.innerWidth < 768) {
            image.src = `/images/${key}-mobile.svg`;
        } else {
            image.src = `/images/${key}.svg`;
        }
        containers.forEach((container) => {
            container.classList.remove('d-block');
            container.classList.add('d-none');
        });
        if (selectedContainer) {
            selectedContainer.classList.replace('d-none', 'd-block');
        } 
    }

};