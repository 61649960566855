window.changeTabsImage = (widgetId, images, loopIndex) => {
    let widgetTabs = document.querySelectorAll(`.single-tab-${widgetId}`)
    let imageTabs = (document.querySelector(`#widget-tabs-img-${widgetId}`)).querySelectorAll('img')
    Array.from(widgetTabs).map(function(tab) {
        if (tab.classList.contains('hover-class')) {
            tab.classList.remove('hover-class');
        }
    });

    Array.from(imageTabs).map((image, index) => {
        if (image.classList.contains('show-image')) {
            image.classList.remove('show-image');
        }
        if(loopIndex-1 === index){
            image.classList.add('show-image');
        }
    });
    document.querySelector(`#widget-tabs-img-${widgetId}`).classList.add("show-image")
    document.querySelector(`#single-tab-${widgetId}-${loopIndex}`).classList.add("hover-class")
}


window.tabsSliderInit = (widgetId, tabs) =>{
    let swiperOptions = {
        pagination: {
            el: `.swiper-pagination-tabs-${widgetId}`,
          }, 
          mousewheel: {
            forceToAxis: true,
            eventsTarget: `.overview-tabs-carousel-${widgetId} .swiper-wrapper`,
        },
        preventClicks: true,
        preventClicksPropagation: true,
        slidesPerView:"auto",
        centeredSlides: false,
        spaceBetween:0,
        updateOnWindowResize: true,
        slidesOffsetAfter:(tabs * 341 + 16) > window.innerWidth ? 16 : 0,
        slidesOffsetBefore:(tabs * 341 + 16) > window.innerWidth ? 16 : 0
      
      }

      var swiper = new window.Swiper(`.overview-tabs-carousel-${widgetId}`, swiperOptions); 

        let sliderContainer = document.querySelector(`.swiper-wrapper-${widgetId}`);
        let pagination = document.querySelector(`.swiper-pagination-tabs-${widgetId}`);
            if((tabs * 341 + 16) > window.innerWidth){
                if(pagination.classList.contains('d-none')){
                    pagination.classList.replace('d-none', 'd-flex')
                }
                if(sliderContainer.classList.contains('d-flex', 'justify-content-center')){
                    sliderContainer.classList.remove('d-flex', 'justify-content-center')
                }
            } else {
                if(!sliderContainer.classList.contains('d-flex', 'justify-content-center')){
                    sliderContainer.classList.add('d-flex', 'justify-content-center')
                }
                if(!pagination.classList.contains('d-none') ){
                    pagination.classList.add('d-none')
                }
            }
      window.addEventListener('resize', function() {
        let sliderContainer = document.querySelector(`.swiper-wrapper-${widgetId}`);
        let pagination = document.querySelector(`.swiper-pagination-tabs-${widgetId}`);

            if((tabs * 341 + 16) > window.innerWidth){
                swiper.params.slidesOffsetAfter = 16
                swiper.params.slidesOffsetBefore = 16
                swiper.update();
    
                if(pagination.classList.contains('d-none')){
                    pagination.classList.replace('d-none', 'd-flex')
                }
                if(sliderContainer.classList.contains('d-flex', 'justify-content-center')){
                    sliderContainer.classList.remove('d-flex', 'justify-content-center')
                }
            } else {
                swiper.params.slidesOffsetAfter = 0
                swiper.params.slidesOffsetBefore = 0
                swiper.update()
                if(!sliderContainer.classList.contains('d-flex', 'justify-content-center')){
                    sliderContainer.classList.add('d-flex', 'justify-content-center')
                }
                if(!pagination.classList.contains('d-none') ){
                    pagination.classList.add('d-none')
                }
            }
      })
}