window.loadGoogleMapsAPI = (callback, region, lang, apiKey)=> {
    if (typeof google !== 'undefined' && google.maps) {
        callback();
        return;
    }

    const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
    if (existingScript) {
        existingScript.addEventListener('load', callback);
        return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&region=${region}&language=${lang}`;

    script.addEventListener('load', callback);
    document.body.appendChild(script);
}

window.initMapWidget = (mapData) => {
    const widgetName = mapData.widgetName;
    const idWidget = mapData.idWidget;
    const otherAddresses = mapData.otherAddresses;

    const map = new google.maps.Map(document.getElementById(`map_${widgetName}_${idWidget}`), {
        zoom: 15,
        center: { lat: parseFloat(otherAddresses[0].latitude), lng: parseFloat(otherAddresses[0].longitude) },
        disableDefaultUI: true
    });

    const bounds = new google.maps.LatLngBounds();

    otherAddresses.forEach((address, index) => {
        const position = new google.maps.LatLng(address.latitude, address.longitude);
        bounds.extend(position);

        const marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: null
        });

        if (otherAddresses.length > 1) {
            const tooltipDiv = document.createElement('div');
            tooltipDiv.className = 'custom-tooltip';
            tooltipDiv.innerHTML = `
                <a href="https://www.google.com/maps/dir/?api=1&destination=${address.latitude},${address.longitude}" 
                   target="_blank" 
                   style="position: absolute; background-color: white; padding: 10px; font-size: 14px; border-radius: 4px; z-index: 10; text-decoration: none;">
                    <strong>${address.pinLabel}</strong>
                </a>
            `;

            document.getElementById(`map_${widgetName}_${idWidget}`).appendChild(tooltipDiv);

            const overlay = new google.maps.OverlayView();

            overlay.onAdd = function() {
                const panes = this.getPanes();
                panes.floatPane.appendChild(tooltipDiv);
            };

            overlay.draw = function() {
                const projection = this.getProjection();
                const point = projection.fromLatLngToDivPixel(marker.getPosition());
                if (point) {
                    tooltipDiv.style.left = `${point.x - 70}px`;
                    tooltipDiv.style.top = `${point.y + 10}px`;
                }
            };

            overlay.setMap(map);
        }

        marker.addListener("click", () => {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${address.latitude},${address.longitude}`, '_blank');
        });
    });

    if (otherAddresses.length > 1) {
        map.fitBounds(bounds);
    }
};