window.initMap = (mapData) => {
    const myLatLng = {
        lat: parseFloat(mapData.latitude),
        lng: parseFloat(mapData.longitude)
    };

    const map = new google.maps.Map(document.getElementById(`form_map_${mapData.widgetName}_${mapData.idWidget}`), {
        zoom: 15,
        center: myLatLng,
        disableDefaultUI: true
    });

    new google.maps.Marker({
        position: myLatLng,
        map: map
    });
}