window.initFormSelectStyle = () => {
    var $ = jQuery;
    setTimeout(() => {
        let selectList = document.querySelectorAll("select")
        selectList.forEach(function (select) {
            var selectId = select.id;
            $(`#${selectId}`).select2({ 
                minimumResultsForSearch: -1
            });
            $(`#${selectId}`).on("change.select2", function(){
                // controllo se il change della select aggiunge delle select al form
                initFormSelectStyle()
            });
        });
    }, 500)
}