window.updateScrollPartnership = (partnersWrapper, btnScrollPrev, btnScrollNext ) => {
    const maxScrollLeft = partnersWrapper.scrollWidth - partnersWrapper.clientWidth;
        if (partnersWrapper.scrollLeft <= 0) {
            btnScrollPrev.disabled = true;
            btnScrollPrev.classList.add('swiper-button-disabled');
        } else {
            btnScrollPrev.disabled = false;
            btnScrollPrev.classList.remove('swiper-button-disabled');
        }

        if (partnersWrapper.scrollLeft >= maxScrollLeft) {
            btnScrollNext.disabled = true;
            btnScrollNext.classList.add('swiper-button-disabled');
        } else {
            btnScrollNext.disabled = false;
            btnScrollNext.classList.remove('swiper-button-disabled');
        }
}

window.checkInitialScrollState = (partnersWrapper, btnScrollPrev, btnScrollNext ) => {
    if(window.innerWidth > 768) {
   
    const maxScrollLeft = partnersWrapper.scrollWidth - partnersWrapper.clientWidth;

    if (maxScrollLeft <= 0) {
        btnScrollPrev.style.display = 'none';
        btnScrollNext.style.display = 'none';
    } else {
        btnScrollPrev.style.display = 'block';
        btnScrollNext.style.display = 'block';

       window.updateScrollPartnership(partnersWrapper, btnScrollPrev, btnScrollNext)
    }
}
}