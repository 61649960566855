// assets/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import jQuery from "jquery";


window.jQuery = jQuery;

import select2 from 'select2';

window.Select2 = select2;

//Import AOS's JS
import AOS from "aos";

AOS.init({
    once: true
});

//Import Swiper's JS
import Swiper from "swiper/bundle";
import {Navigation, Pagination, Keyboard} from 'swiper/modules';

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Pagination = Pagination;
window.Keyboard = Keyboard

// any CSS you import will output into a single css file (app.css in this case)
import './scss/main.scss';
import './scss/editmode.scss';

// Import Masonry layout
import Masonry from 'masonry-layout';

window.Masonry = Masonry

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

window.Bootstrap = bootstrap
import '../templates/areas/overview-countdown/overview_countdown'
import '../templates/areas/header/header'
import '../templates/areas/hero-image/hero-image'
import '../templates/areas/hero-video/hero-video'
import '../templates/areas/image-card-scroll/image_card_scroll'
import '../templates/areas/video/video'
import '../templates/areas/outline-card-scroll-horizontal/outline_card_scroll_horizontal'
import '../templates/areas/sub-header/sub_header'
import '../templates/areas/gallery/gallery'
import '../templates/areas/overview-tabs/overview_tabs'
import '../templates/areas/map/map'
import '../templates/areas/people-card/people_card'
import '../templates/areas/community-voices/community_voices'
import '../templates/areas/form/form'
import '../templates/layouts/event/show'
import '../templates/areas/form-and-map/form-and-map'
import '../templates/areas/listing-news/listing_news'
import '../templates/includes/popup/popup'
import '../templates/areas/tab/tab'
import '../templates/areas/kpi/kpi'
import '../templates/areas/learning-pillars/learning_pillars'
import '../templates/areas/multimedia/multimedia'
import '../templates/areas/schools-card/schools-card'
import '../templates/areas/schools-map/schools_map'
import '../templates/areas/timeline-image/timeline-image'
import '../templates/areas/partnership/partnership'
import '../templates/areas/wheel/wheel'

const whatsappBtn = document.querySelectorAll('.whatsapp-cta');
whatsappBtn.forEach(btn => {
    btn.addEventListener('click', function (event) {
        console.log(8)
        window.dataLayer?.push({
            event: 'click_whatsapp'
        });
        event.stopImmediatePropagation();
    });
});

function addInputListeners() {
    const inputs = document.querySelectorAll('.pimcore_editable.pimcore_editable_input, .pimcore_editable.pimcore_editable_wysiwyg, .pimcore_editable.pimcore_editable_textarea');

    inputs.forEach(input => {
        const textContent = input.textContent.replace(/<\/?(b|i|strong|em)[^>]*>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
        updateCharCount(input, textContent.length);
        input.addEventListener('keyup', function () {
            const textContent = input.textContent.replace(/<\/?(b|i|strong|em)[^>]*>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
            updateCharCount(input, textContent.length);
        });
        input.addEventListener('keydown', function () {
            const textContent = input.textContent.replace(/<\/?(b|i|strong|em)[^>]*>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
            updateCharCount(input, textContent.length);
        });
        input.addEventListener('focus', function () {
            const textContent = input.textContent.replace(/<\/?(b|i|strong|em)[^>]*>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
            updateCharCount(input, textContent.length);
        });
    });

    function updateCharCount(input, count) {
        let charCountElement = input.parentElement.querySelector('.char-count');
        if (charCountElement) {
            charCountElement.innerHTML = count;

            let charMax = input.parentElement.querySelector('.char-max');
            if (charMax) {
                let charMaxText = charMax.textContent.replace('/', '');
                if (count > charMaxText) {
                    charMax.parentElement.classList.add('warning');
                } else {
                    charMax.parentElement.classList.remove('warning');
                }
            }
        }
    }
}

function addListenerInBlockBtn() {
    document.querySelectorAll('.pimcore_block_plus').forEach(button => {
        button.addEventListener('click', function () {
            setTimeout(() => {
                addListenerInBlockBtn();
                addInputListeners();
            }, 200);
        });
    })
}

document.addEventListener("DOMContentLoaded", function () {
    addInputListeners();

    // VIdeos
    var videos = document.querySelectorAll('video.lazy-video');

    if ("IntersectionObserver" in window) {
        var videoObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }
                    video.target.load();
                    videoObserver.unobserve(video.target);
                }
            });
        });
        videos.forEach(function (video) {
            videoObserver.observe(video);
        });
    }
});

window.toggleEditModeCollapsible = function (widgetId) {
    addInputListeners();
    addListenerInBlockBtn()

    let element = document.getElementById(widgetId);
    let elementToToggle = new bootstrap.Collapse(element, {toggle: false, hide: true})

    console.debug(elementToToggle._isShown(elementToToggle._element));

    elementToToggle._isShown(elementToToggle._element) ? elementToToggle.hide() : elementToToggle.show();
}

window.refreshScrollSpy = function () {
    const dataSpyList = document.querySelectorAll('[data-bs-spy="scroll"]')
    dataSpyList?.forEach(dataSpyEl => {
        bootstrap.ScrollSpy.getInstance(dataSpyEl)?.refresh()
    })
}

document.addEventListener('aos:in', ({detail}) => {
    refreshScrollSpy()
});

document.addEventListener('aos:out', ({detail}) => {
    refreshScrollSpy()
});

document.addEventListener("DOMContentLoaded", () => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});


window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    refreshScrollSpy()
});


document.addEventListener('click', function (event) {
    if (event.target?.tagName === 'A' && event.target?.href?.startsWith('tel:')) {
        window.dataLayer?.push({
            event: 'click_tel'
        });
        event.stopImmediatePropagation();
    } else if (event.target?.href?.startsWith('mailto:')) {
        window.dataLayer?.push({
            event: 'click_email'
        });
        event.stopImmediatePropagation();
    }
});

// modal with video used in widgets: community voices, learning pillars, timeline icon, timeline image
window.customVideoControls = (widgetName, idWidget, rtl) => {
    let widgetId = `${widgetName}_${idWidget}`;
    let video = document.querySelector(`#${widgetId} video#widgetCustomVideo_${idWidget}`);

    let div = document.querySelector(`#${widgetId} .video-slider-wrapper #progressIndicator_${idWidget}`);
    let container = document.querySelector(`#${widgetId} .video-slider-wrapper #video-progress_${idWidget}`);
    let isPaused = false;

    function updateContainerWidth() {
        let containerWidth = container?.clientWidth;
        let divWidth = div?.clientWidth;
        let distanceToTravel = containerWidth - divWidth;
        let distancePerSecond = distanceToTravel / video?.duration;
        return distancePerSecond;
    }

    function animate() {
        if (video.getAttribute('src')) {
            if (!isPaused) {
                let distancePerSecond = updateContainerWidth();
                let currentPosition = distancePerSecond * video.currentTime;

                if (div) {
                    if (rtl === true) {
                        div.style.transform = `translateX(${-currentPosition}px)`;
                    } else {
                        div.style.transform = `translateX(${currentPosition}px)`;
                    }
                }

            }
            requestAnimationFrame(animate);
        }
    }

    function togglePlayPause() {
        if (isPaused) {
            video.play();
        } else {
            video.pause();
        }
        isPaused = !isPaused;
        updatePlayPauseButton();
    }

    function updatePlayPauseButton() {
        const button = document.getElementById(`pauseButton_${idWidget}`);
        if (button) {
            const playIcon = button.querySelector('.video-play-icon');
            const resumeIcon = button.querySelector('.video-resume-icon');

            if (isPaused) {
                playIcon.style.display = 'block';
                resumeIcon.style.display = 'none';
            } else if (!isPaused || video === null) {
                playIcon.style.display = 'none';
                resumeIcon.style.display = 'block';
            }
        }
    }

    window.addEventListener('resize', () => {
        updateContainerWidth();
        animate();
    });

    video?.addEventListener('loadedmetadata', function () {
        updateContainerWidth();
        if (!isPaused) {
            animate();
        }
    });

    video?.addEventListener('play', function () {
        if (!isPaused) {
            animate();
        }
    });

    video?.addEventListener('pause', function () {
        cancelAnimationFrame(animate);
    });

    const button = document.getElementById(`pauseButton_${idWidget}`);
    button?.addEventListener('click', togglePlayPause);

    video?.addEventListener('play', updatePlayPauseButton);
    video?.addEventListener('pause', updatePlayPauseButton);

    video?.play();
    updatePlayPauseButton();
};
