window.popupInit = () => {
  const openPopupLink = document.getElementById("open-popup-link");
  const closePopupBtn = document.getElementById("close-popup");
  const popup = document.getElementById("popup-isp");
  
  // Verifica se il popup deve essere mostrato
  const shouldShowPopup = sessionStorage.getItem("showPopup");
  if (shouldShowPopup !== "false") {
    popup.style.display = "flex";
  }else{
    popup.style.display = "none";
  }
  
  // Gestione del clic sul pulsante per aprire il popup
  openPopupLink.addEventListener("click", function() {
    popup.style.display = "flex";
    sessionStorage.setItem("showPopup", "false");
  });
  
  // Gestione del clic sul pulsante per chiudere il popup
  closePopupBtn.addEventListener("click", function() {
    popup.style.display = "none";
    sessionStorage.setItem("showPopup", "false");
  });
}