window.setInitialPosition = (
    widgetId,
    widgetName,
    circleGroup,
    texts,
    descriptionElem
) => {
    let currentAngle = 0;
    const totalWords = texts.length;
    const offsetAngle = 55;

    circleGroup.style.transform = `rotate(${offsetAngle}deg)`;
    window.updateActiveWord(
        widgetId,
        widgetName,
        circleGroup,
        texts,
        descriptionElem,
        currentAngle
    );

    function rotateCircle(direction) {
        currentAngle += direction * (360 / totalWords);
        const rotationAngle = -currentAngle + offsetAngle;
        circleGroup.style.transform = `rotate(${rotationAngle}deg)`;
        window.updateActiveWord(
            widgetId,
            widgetName,
            circleGroup,
            texts,
            descriptionElem,
            currentAngle
        );
    }

    document
        .querySelector(`.wheel-button-prev-${widgetId}`)
        .addEventListener("click", () => rotateCircle(-1));
    document
        .querySelector(`.wheel-button-next-${widgetId}`)
        .addEventListener("click", () => rotateCircle(1));

    texts.forEach((text, index) => {
        text.addEventListener("click", () => {
            const anglePerWord = 360 / totalWords;
            const targetAngle = index * anglePerWord;
            const normalizedCurrentAngle = (360 + (currentAngle % 360)) % 360;
            let rotationDelta = targetAngle - normalizedCurrentAngle;

            if (rotationDelta > 180) {
                rotationDelta -= 360;
            } else if (rotationDelta < -180) {
                rotationDelta += 360;
            }

            currentAngle += rotationDelta;
            const rotationAngle = -currentAngle + offsetAngle;
            circleGroup.style.transform = `rotate(${rotationAngle}deg)`;
            window.updateActiveWord(
                widgetId,
                widgetName,
                circleGroup,
                texts,
                descriptionElem,
                currentAngle
            );
        });
    });
};

window.updateActiveWord = (
    widgetId,
    widgetName,
    circleGroup,
    texts,
    descriptionElem,
    currentAngle
) => {
    const totalWords = texts.length;
    const anglePerWord = 360 / totalWords;

    const normalizedAngle = (360 + (currentAngle % 360)) % 360;
    const activeIndex = Math.round(
        (normalizedAngle / anglePerWord + totalWords) % totalWords
    );

    texts.forEach((text, i) => {
        if (i === activeIndex) {
            text.classList.add("active");
            const color = getTextColor(text);
            window.updateCircleColor(color, widgetId);

            const activeSkillDiv = window.getSkillDiv(i, widgetId, widgetName);
            if (activeSkillDiv) {
                const skillTitle = activeSkillDiv.querySelector(".skill-title");
                const skillItemTitles =
                    activeSkillDiv.querySelectorAll(".skill-item-title");

                if (skillTitle) skillTitle.style.color = color;
                skillItemTitles.forEach((item) => (item.style.color = color));
            }
        } else {
            text.classList.remove("active");
        }
    });

    const activeText = texts[activeIndex];
    descriptionElem.textContent = activeText.dataset.description;

    for (let i = 0; i < totalWords; i++) {
        const skillDiv = window.getSkillDiv(i, widgetId, widgetName);
        if (i === activeIndex) {
            skillDiv.classList.replace("d-none", "d-flex");
        } else {
            skillDiv.classList.replace("d-flex", "d-none");
        }
    }
};

window.updateCircleColor = (color, widgetId) => {
    const innerShadowFilter = document.getElementById("inset-shadow");
    if (innerShadowFilter) {
        const feFlood = innerShadowFilter.querySelector("feFlood");
        if (feFlood) feFlood.setAttribute("flood-color", color);
    }

    const shadowCircle = document.querySelector(`#circle-${widgetId}`);
    if (shadowCircle) shadowCircle.setAttribute("stroke", color);

    const smallCircle = document.querySelector(`.small-circle-${widgetId}`);
    if (smallCircle) smallCircle.setAttribute("fill", color);

    window.updateCurveColor(color);
};

window.updateCurveColor = (color) => {
    const shadowFilter = document.querySelector(
        "filter#inset-shadow-top-curve feFlood"
    );
    if (shadowFilter) {
        shadowFilter.setAttribute("flood-color", `${color}`);
    }

    const curveBorderPath = document.querySelector("path#top-curve-path");
    if (curveBorderPath) {
        curveBorderPath.setAttribute("stroke", color);
    }
};

window.getTextColor = (element) => {
    const textPath = element.querySelector("textPath");
    return window.getComputedStyle(textPath).fill;
};

window.getSkillDiv = (index, widgetId, widgetName) => {
    return document.querySelector(`.skills-${widgetName}-${widgetId}-${index}`);
};
