window.scrollSubHeader = (widgetName, idWidget) => {
    let widgetId = `${widgetName}_${idWidget}`;
    let el = document.getElementById(widgetId);
    let originalElOffset = el.offsetTop;
    function handleScrollSubHeader() {
        let headerHeight = window.innerWidth <= 1200 ? 68 : document.getElementById("header")?.offsetHeight;
        if(el.offsetTop < window.scrollY + headerHeight) {
            el.classList.add('sub-header-lock');
            el.style.top = headerHeight + "px";
            refreshScrollSpy();
        } 
        if(window.scrollY + headerHeight <= el.offsetTop || window.scrollY + headerHeight <= originalElOffset) {
            el.classList.remove('sub-header-lock');
            refreshScrollSpy();
        }
    }
    window.addEventListener('scroll', handleScrollSubHeader);
}

