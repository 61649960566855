window.openCommunityVideoPillars = (idWidget, widgetName, rtl, srcVideo) => {
    let modalVideo = document.querySelector(`.modal-video-${idWidget}`);
    let videoContainer = document.querySelector(`#widgetCustomVideo_${idWidget}`);
    
    if (modalVideo.classList.contains("d-none")) {
        modalVideo.classList.replace("d-none", "d-flex");
        document.body.style.overflow = "hidden";
        videoContainer.setAttribute('src', srcVideo);  
        document.addEventListener('keydown', onEscapeCloseModal);
    } else {
        closeModal(idWidget);
    }
};

window.onCloseModalVideoPillars = (idWidget, widgetName) => {
    closeModal(idWidget);
};

function closeModal(idWidget) {
    let modalVideo = document.querySelector(`.modal-video-${idWidget}`);
    let video = document.querySelector(`video#widgetCustomVideo_${idWidget}`);

    if (modalVideo.classList.contains("d-flex")) {
        modalVideo.classList.replace("d-flex", "d-none");
        document.body.style.overflow = "auto";
        if (video) {
            video.pause();
            video.muted = true;
            video.setAttribute('src', "");  
        }
        document.removeEventListener('keydown', onEscapeCloseModal);
    }
}

function onEscapeCloseModal(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
        const openModal = document.querySelector('.modal-video.d-flex');
        if (openModal) {
            const idWidget = openModal.className.match(/modal-video-(\d+)/)[1];
            closeModal(idWidget);
        }
    }
}