window.handleScroll = (widgetName, idWidget) => {
    var fixedDiv = document.getElementById(
        `isp-scrollspy-${widgetName}_${idWidget}`
    );
    let divContainer = document.querySelector(`#${widgetName}_${idWidget}`);

    function handleScrollTabs() {
        let headerHeight =
            window.innerWidth <= 1200
                ? 68
                : document.getElementById("header").offsetHeight;
        let headerSubHeader =
            document.querySelectorAll(".sub-header-wrapper") &&
            document.querySelectorAll(".sub-header-wrapper")?.[0]
                ? document.querySelectorAll(".sub-header-wrapper")?.[0]
                      ?.offsetHeight
                : 0;
        // controllo se mettere in fixed la sezione Tabs
        if (divContainer?.offsetTop < window.scrollY) {
            fixedDiv.classList.add("position-fixed");
            fixedDiv.style.top = headerHeight + headerSubHeader + "px";
            window.refreshScrollSpy();
        } else {
            fixedDiv.classList.remove("position-fixed");
            fixedDiv.style.top = "0px";
            window.refreshScrollSpy();
        }

        // Allo scroll gestisco le visualizzazione della voce attiva

        // Seleziono tutte le anchor nel documento
        const anchorList = document.querySelectorAll(
            `#${widgetName}_${idWidget} a[href^="#"]`
        );

        // Variabile per memorizzare l'ultimo elemento ancorato in pagina
        let lastElementAnchor = null;
        let lastAnchor;
        // Itera su tutti gli elementi di anchorList
        anchorList.forEach((anchor) => {
            // Recupero l'id dell'anchor dall'attributo href
            const idAnchor = anchor.getAttribute("href").substring(1);

            // Seleziona l'elemento ancorato utilizzando l'id
            const anchoredElement = document.getElementById(idAnchor);

            // Verifico se l'elemento ancorato corrente è più in basso in pagina dell'elemento memorizzato
            if (
                anchoredElement &&
                (!lastElementAnchor ||
                    anchoredElement.getBoundingClientRect().bottom >
                        lastElementAnchor.getBoundingClientRect().bottom)
            ) {
                lastAnchor = anchor;
                lastElementAnchor = anchoredElement;
            }
        });

        let top, bottom;
        if (lastElementAnchor) {
            const rect = lastElementAnchor.getBoundingClientRect();
            const scrollTop =
                window.scrollY || document.documentElement.scrollTop;
            top = rect.top + scrollTop;
            bottom = rect.bottom + scrollTop;
        }

        // Verifico se la posizione dello scroll si trova tra il top e il bottom dell'ultimo elemento ancorato
        if (window.scrollY >= top && window.scrollY <= bottom) {
            // Aggiungo la classe "active" all'anchor dell'ultimo elemento
            if (!lastAnchor.classList.contains("active")) {
                lastAnchor.classList.add("active");
            }
        }

        window.refreshScrollSpy();
    }

    window.addEventListener("scroll", () => {
        handleScrollTabs();
    });
};

window.scrollToAnchorSection = () => {
    document.querySelectorAll("#isp-scrollspy .nav-link").forEach((el) => {
        el.addEventListener("click", (event) => {
            const idAnchor = el.getAttribute("href").substring(1);
            let element = document.getElementById(idAnchor);
            if (element) {
                event.preventDefault();
                // scroll alla sezione ancorata
                window.scrollTo(0, element.offsetTop - 85);
            }
        });
    });
};
