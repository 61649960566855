window.updateScrollButtons = (timelineWrapper, btnScrollPrev, btnScrollNext ) => {
    const maxScrollLeft = timelineWrapper.scrollWidth - timelineWrapper.clientWidth;

    if (timelineWrapper.scrollLeft <= 0) {
        btnScrollPrev.disabled = true;
        btnScrollPrev.classList.add('swiper-button-disabled');
    } else {
        btnScrollPrev.disabled = false;
        btnScrollPrev.classList.remove('swiper-button-disabled');
    }

    if (timelineWrapper.scrollLeft >= maxScrollLeft) {
        btnScrollNext.disabled = true;
        btnScrollNext.classList.add('swiper-button-disabled');
    } else {
        btnScrollNext.disabled = false;
        btnScrollNext.classList.remove('swiper-button-disabled')
    }
}

window.checkInitialTimelineScroll = (timelineWrapper, btnScrollPrev, btnScrollNext ) => {
    if(window.innerWidth > 768) {
   
    const maxScrollLeft = timelineWrapper.scrollWidth - timelineWrapper.clientWidth;

    if (maxScrollLeft <= 0) {
        btnScrollPrev.style.display = 'none';
        btnScrollNext.style.display = 'none';
    } else {
        btnScrollPrev.style.display = 'block';
        btnScrollNext.style.display = 'block';

       window.updateScrollButtons(timelineWrapper, btnScrollPrev, btnScrollNext)
    }
}
}