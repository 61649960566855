function updateDropdownClass() {
    let dropdownDiv = document.querySelector(".dropdown-languages-switcher");
    if (window.innerWidth > 1200) {
        dropdownDiv.classList.add("dropdown");
        dropdownDiv.classList.remove("dropup");
    } else {
        dropdownDiv.classList.add("dropup");
        dropdownDiv.classList.remove("dropdown");
    }
}

document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        if (typeof Weglot !== "undefined") {
            console.log("Weglot is defined");
            console.log("Weglot initialized state:", Weglot.initialized);

            // If Weglot is already initialized, call langSwitcher immediately
            if (Weglot.initialized) {
                console.log("Weglot already inizialized");
                langSwitcher();
                updateDropdownClass();
            } else {
                Weglot.on("initialized", function () {
                    console.log("Weglot initialized event triggered");
                    langSwitcher();
                    updateDropdownClass();
                });
            }
        } else {
            console.log("Weglot is not defined");
        }
    }, 2500); // 2.5 seconds delay

    // Show/Hide description primo livello
    document.addEventListener("scroll", (e) => {
        const headerClass = document
            .getElementsByTagName("header")?.[0]
            ?.classList.contains("hide-description");
        if (window.innerWidth > 1200 && window.scrollY > 50 && !headerClass) {
            document
                .getElementsByTagName("header")?.[0]
                ?.classList.add("hide-description");
        } else if (
            window.innerWidth > 1200 &&
            window.scrollY < 50 &&
            headerClass
        ) {
            document
                .getElementsByTagName("header")?.[0]
                ?.classList.remove("hide-description");
        }
        if (document.querySelector(".school-children-select-container")) {
            const select = document.querySelector(
                ".school-children-select-container"
            );
            if (window.innerWidth < 1200 && window.scrollY > 88) {
                select.style.position = "fixed";
                select.style.top = "0";
            } else {
                select.style.position = "absolute";
                select.style.top = "68px";
            }
        }
    });

    const whatsappBtn = document.querySelector(".whatsapp-cta-header");
    if (whatsappBtn) {
        whatsappBtn.addEventListener("click", function (event) {
            window.dataLayer?.push({
                event: "click_whatsapp",
            });
            event.stopImmediatePropagation();
        });
    }

    // Apertura menu mobile primo livello
    let headerCollapsibleFirstLevel = document.getElementById(
        "navbarCollapseHeader"
    );
    headerCollapsibleFirstLevel?.addEventListener(
        "shown.bs.collapse",
        function (e) {
            if (window.innerWidth < 1200) {
                document.body.style.overflow = "hidden";
            }
        }
    );
    // Chiusura menu mobile primo livello
    headerCollapsibleFirstLevel?.addEventListener(
        "hidden.bs.collapse",
        function () {
            if (window.innerWidth < 1200) {
                document.body.style.overflow = "auto";
            }
        }
    );
    // Apertura menu mobile secondo livello
    let headerCollapsibleSecondLevel = document.querySelectorAll(
        "#navbarCollapseHeader .navbar-nav"
    )[0];
    headerCollapsibleSecondLevel?.addEventListener(
        "shown.bs.dropdown",
        function (e) {
            if (window.innerWidth < 1200) {
                headerCollapsibleFirstLevel.scrollTop = 0;
                console.log(headerCollapsibleFirstLevel.scrollTop);
                setTimeout(() => {
                    if (headerCollapsibleFirstLevel.scrollTop === 0) {
                        headerCollapsibleFirstLevel.style.overflow = "hidden";
                    } else {
                        headerCollapsibleFirstLevel.scrollTop = 0;
                        headerCollapsibleFirstLevel.style.overflow = "hidden";
                    }
                }, 100);
            }
        }
    );
    // Chiusura menu mobile secondo livello
    headerCollapsibleSecondLevel?.addEventListener(
        "hidden.bs.dropdown",
        function () {
            if (window.innerWidth < 1200) {
                headerCollapsibleFirstLevel.style.overflow = "auto";
            }
        }
    );

    window.addEventListener("resize", () => {
        // controllo anche se ci sono modali aperte
        if (
            window.innerWidth > 1200 &&
            document.querySelectorAll(".modal.show").length === 0
        ) {
            document.body.style.overflow = "auto";
        }
    });

    //apertura search
    let searchCollapsible = document.getElementById("dropdownMenuSearch");
    searchCollapsible?.addEventListener("show.bs.dropdown", function () {
        if (window.innerWidth > 1200) {
            document.body.style.overflow = "hidden";
        }
    });

    searchCollapsible?.addEventListener("hide.bs.dropdown", function () {
        document.body.style.overflow = "auto";
    });

    //dropdown list position of languages selector
    let dropdown = document.getElementById("languageSwitcherMenu");
    window.addEventListener("resize", () => {
        updateDropdownClass();
    });

    function langSwitcher() {
        let languageSwitcher = document.querySelector(
            ".dropdown.dropdown-languages-switcher"
        );

        let iconSwitcher = document.querySelector(
            ".dropdown-languages-switcher"
        );

        let languageSwitcherMenu = document.getElementById(
            "languageSwitcherMenu"
        );
        iconSwitcher.classList.replace("d-none", "d-flex");

        if (typeof Weglot === "undefined") {
            languageSwitcher.classList.add("d-none");
            return;
        }

        // Create array of options to be added
        let availableLanguages = Weglot.options.languages
            .map(function (language) {
                return language.language_to;
            })
            .concat(Weglot.options.language_from);

        let currentLang = Weglot.getCurrentLang();

        // Create and append the options for desktop
        for (let i = 0; i < availableLanguages.length; i++) {
            let lang = availableLanguages[i];
            let li = document.createElement("li");
            li.className = "px-4 py-1";
            let a = document.createElement("a");
            a.href = "#";
            a.className =
                "text-uppercase align-items-xl-center d-flex color-school-text";
            if (lang === currentLang) {
                a.className += " fw-bold text-decoration-underline";
            }
            a.textContent = Weglot.getLanguageName(lang);
            a.dataset.lang = lang;
            li.appendChild(a);
            languageSwitcherMenu.appendChild(li);
        }

        languageSwitcherMenu.addEventListener("click", function (event) {
            if (event.target.tagName === "A") {
                event.preventDefault();
                Weglot.switchTo(event.target.dataset.lang);
            }
        });

        Weglot.on("languageChanged", function (lang) {
            let links = languageSwitcherMenu.querySelectorAll("a");
            links.forEach(function (link) {
                if (link.dataset.lang === lang) {
                    link.classList.add("fw-bold", "text-decoration-underline");
                } else {
                    link.classList.remove(
                        "fw-bold",
                        "text-decoration-underline"
                    );
                }
            });
        });
    }
});
