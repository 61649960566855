
    window.imageCardInit = (widgetId, carouselLength ) =>{
        let swiperOptions = {
          mousewheel: {
            forceToAxis: true,
            eventsTarget: `.image-card-carousel-${widgetId} .swiper-wrapper`,
        },
            slidesPerView:"auto",
            centeredSlides: false,
            keyboard: {
              enabled: true,
              onlyInViewport: true,
            },
            spaceBetween: 16,
            navigation: {
              nextEl: `.swiper-image-card-button-next-${widgetId}`,
              prevEl: `.swiper-image-card-button-prev-${widgetId}`,
            },
            preventClicks: true,
        preventClicksPropagation: true,
            on: {
              slideChange: function () {
                var rangeInput = document.getElementById(`rangeInput-${widgetId}`);
                if(rangeInput){
                  rangeInput.value = this.activeIndex;
                }
              }
            }
          }
        var swiper = new window.Swiper(`.image-card-carousel-${widgetId}`, swiperOptions);   
        window.addEventListener('resize', function() {
            var rangeInput = document.getElementById(`rangeInput-${widgetId}`);
            if(rangeInput){
              rangeInput.max = carouselLength - Math.round((Math.max((document.querySelector(".container").offsetWidth) / 355)))
            }
            if(carouselLength>2 && window.innerWidth < 1999 ){
              var swiper = new window.Swiper(`.image-card-carousel-${widgetId}`, swiperOptions);   
                }  
          });
          
          var rangeInput = document.getElementById(`rangeInput-${widgetId}`);
          if(rangeInput){
            rangeInput.max = carouselLength - Math.round((Math.max((document.querySelector(".container").offsetWidth) / 355)))
            rangeInput.addEventListener('input', function() {
              var value = parseInt(this.value); // Ottieni il valore dell'input come numero intero
              swiper.slideTo(value);
              });
          }
  
        }
  

       