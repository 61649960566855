window.sliderHeroInit = (widgetId) =>{
    let swiperOptions = {
        mousewheel: {
            forceToAxis: true,
            eventsTarget: `.hero-swiper-${widgetId} .swiper-wrapper`,
        },
        pagination: {
          el: `.hero-swiper-${widgetId} .swiper-pagination`,
          clickable: true,
        }, 
        slidesPerView: 1,
        centeredSlides: true,
        preventClicks: true,
        preventClicksPropagation: true,
        navigation: {
            nextEl: `.swiper-hero-button-next-${widgetId}`,
            prevEl: `.swiper-hero-button-prev-${widgetId}`,
          },
    }
    var swiperGallery = new window.Swiper(`.hero-swiper-${widgetId}`, swiperOptions);    

}

window.scrollHeroToNextSection = (idWidget) => {
    let currentWidget = document.getElementById(idWidget)
 
    if (currentWidget) {
        var currentWidgetPosition = currentWidget.getBoundingClientRect();
        // Posizione in pixel rispetto al documento
        var posizioneY = currentWidgetPosition.top + window.scrollY;
        // Scroll alla sezione successiva
        if (window.innerWidth < 1200) {
            window.scrollTo(0, currentWidget.scrollHeight + posizioneY - 68);
        } else {
            window.scrollTo(0, currentWidget.scrollHeight + posizioneY - 80);
        }
    }
}
