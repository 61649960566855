window.heroVideoScroll = (widgetId) => {
    let currentWidget = document.getElementById(widgetId)
    if (currentWidget) {
        var currentWidgetPosition = currentWidget.getBoundingClientRect();
        // Posizione in pixel rispetto al documento
        var posizioneY = currentWidgetPosition.top + window.scrollY;
        if (window.innerWidth < 1200) {
            window.scrollTo(0,currentWidget.scrollHeight + posizioneY - 68);
        } else {
            window.scrollTo(0,currentWidget.scrollHeight + posizioneY - 80);
        }
    }
}

window.heroVideoFullscreen = (widgetId) => {
    let currentWidget = document.getElementById(widgetId)

    let video;
    
    if(window.innerWidth < 769) {
        console.log('mobile')
        video = currentWidget.querySelector('#video-mobile');
    } else {
        console.log('desktop')
        video = currentWidget.querySelector('#video-desktop');
    }

    if (video.webkitSupportsFullscreen) {
        video.webkitEnterFullscreen();
        video.play()
        return;
      }
    
      video.requestFullscreen();
      video.play()

    // console.log("heroVideoFullscreen")
    // let currentWidget = document.getElementById(widgetId)
    // const videoElement = currentWidget.querySelector('video');
    // console.log("currentWidget", currentWidget)
    // if (currentWidget) {
    //     console.log("videoElement.requestFullscreen", videoElement.requestFullscreen)
    //     console.log("videoElement.mozRequestFullScreen", videoElement.mozRequestFullScreen)
    //     console.log("videoElement.webkitRequestFullscreen", videoElement.webkitRequestFullscreen)
    //     console.log("videoElement.msRequestFullscreen", videoElement.msRequestFullscreen)
        
    //     console.log("videoElement.webkitEnterFullscreen", videoElement.webkitEnterFullscreen)
        
    //     if (videoElement.requestFullscreen) {
    //         videoElement.requestFullscreen();
    //     } else if (videoElement.mozRequestFullScreen) {
    //         videoElement.mozRequestFullScreen();
    //     } else if (videoElement.webkitRequestFullscreen) {
    //         videoElement.webkitRequestFullscreen();
    //     } else if (videoElement.msRequestFullscreen) {
    //         videoElement.msRequestFullscreen();
    //     } else if (videoElement.webkitEnterFullscreen) {
    //         videoElement.webkitEnterFullscreen()
    //         videoElement.play();
    //     }
    // }
}

// window.handleFullscreenChange = (widgetId) => {

//     console.log("handleFullscreenChange.msRequestFullscreen", document.fullscreenElement)
//     console.log("handleFullscreenChange.requestFullscreen", document.webkitFullscreenElement)
//     console.log("handleFullscreenChange.mozRequestFullScreen", document.mozFullScreenElement)
//     console.log("handleFullscreenChange.webkitRequestFullscreen", document.msFullscreenElement)
       
        
//     console.log("handleFullscreenChange.webkitEnterFullscreen", document.webkitCurrentFullScreenElementè)
        
//     let currentWidget = document.getElementById(widgetId)
//     const myVideo = currentWidget.querySelector('video');

//     console.log("***",  myVideo.webkitEnterFullscreen)

//     if (document.fullscreenElement !== myVideo || 
//         document.webkitFullscreenElement !== myVideo || 
//         document.mozFullScreenElement !== myVideo || 
//         document.msFullscreenElement !== myVideo) {
//         myVideo.muted = true;
//         myVideo.play();
//     }
    
//     if (document.fullscreenElement || document.webkitFullscreenElement || document.webkitEnterFullscreen || document.mozFullScreenElement || document.msFullscreenElement) {
//         console.log('Video in modalità schermo intero');
//       } else {
//         console.log('Video in modalità normale');
//       }
// }
